/* 
	1. Common Styles
	2. Login Page Styles
    3. Enter Code Page Styles
    4. Forgot Password Page Styles
    5. Sign Up Page Styles
	
    Registration/Create Account Page Styles
    Home Page Styles
    Demo Site Styles
    Calculate Score Page Styles
    Select Biometric Page Styles
    Resume Parsing Page Style
    
*/
/* 

background: linear-gradient(0deg, #304CA5, #304CA5),
linear-gradient(0deg, #7EAD18, #7EAD18),
linear-gradient(0deg, #FFFFFF, #FFFFFF);

*/
/* 1. Common Styles Starting */

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

body {
  font-family: 'Mukta', sans-serif;
  background-color: white;
}

:root {
    --LightGrey-Color: #D6D6D6;
    --LightGrey2-Color: #D3D2D2;
    --LightGrey3-Color: #EBEBEB;
    --LightGrey4-Color: #FAFAFA;
    --LightGrey5-Color: #ECECEC;
    --LightGrey6-Color: #F9F9FF;
    --LightGrey7-Color: #B4B4B4;
    --LightGrey8-Color: #F0F0F0;
    --LightGrey9-Color: #C6C6C6;
    --LightGrey10-Color: #F5F5F5;
    --LightGrey11-Color:#9F9D9D;
    --DarkGrey-Color: #626262;
    --DarkGrey2-Color: #8C8C8C;
    --DarkGrey3-Color: #A6A4A4;
    --DarkGrey4-Color: #6F6F6F;
    --LightBlue-Color: #E7EBFF;
    --LightBlue2-Color: #85ABFF;
    --LightBlue3-Color: #E5EEFC;
    --LightBlue4-Color: #E9EBFC;
    --DarkBlue-Color: #0B1470;
    --DarkBlue2-Color: #24397A;
    --DarkBlue3-Color: #010A62;
    --DarkBlue4-Color: #182286;
    --DarkBlue5-Color: #0A1160;
    --LightGreen-Color: #7EAD18;
    --LightGreen2-Color: #DCEFB1;
    --LightGreen3-Color: #22B61F;
    --LightOrange-Color: #FEB36E;
    /* --green-color: #49bf2b!;
    --red: #ff4d62;
    --orange: #fe7f3c;
    --green: #33c18d; */
    /* --Mukta-Font-Family: "Roboto", 'Halvetica', sans-serif; */
}

.Mukta_font {
    font-family: 'Mukta', sans-serif;
}

.LightGrey_Color {
    color: var(--LightGrey-Color);
}

.LightGrey_BgColor {
    background-color: var(--LightGrey-Color);
}

.LightGrey2_Color {
    color: var(--LightGrey2-Color);
}

.LightGrey2_BgColor {
    background-color: var(--LightGrey2-Color);
}

.LightGrey3_Color {
    color: var(--LightGrey3-Color);
}

.LightGrey3_BgColor {
    background-color: var(--LightGrey3-Color) !important;
}

.LightGrey4_Color {
    color: var(--LightGrey4-Color);
}

.LightGrey4_BgColor {
    background-color: var(--LightGrey4-Color) !important;
}

.LightGrey5_Color {
    color: var(--LightGrey5-Color);
}

.LightGrey5_BgColor {
    background-color: var(--LightGrey5-Color) !important;
}

.LightGrey6_Color {
    color: var(--LightGrey6-Color);
}

.LightGrey6_BgColor {
    background-color: var(--LightGrey6-Color) !important;
}

.LightGrey7_Color {
    color: var(--LightGrey7-Color);
}

.LightGrey7_BgColor {
    background-color: var(--LightGrey7-Color) !important;
}

.LightGrey8_Color {
    color: var(--LightGrey8-Color);
}

.LightGrey8_BgColor {
    background-color: var(--LightGrey8-Color) !important;
}

.LightGrey9_Color {
    color: var(--LightGrey9-Color);
}

.LightGrey9_BgColor {
    background-color: var(--LightGrey9-Color) !important;
}

.LightGrey10_Color {
    color: var(--LightGrey10-Color);
}

.LightGrey10_BgColor {
    background-color: var(--LightGrey10-Color) !important;
}

.LightGrey11_Color {
    color: var(--LightGrey11-Color);
}

.LightGrey11_BgColor {
    background-color: var(--LightGrey11-Color) !important;
}

.DarkGrey7_Color {
    color: var(--DarkGrey-Color);
}

.DarkGrey7_BgColor {
    background-color: var(--DarkGrey-Color) !important;
}

.DarkGrey2_Color {
    color: var(--DarkGrey2-Color);
}

.DarkGrey2_BgColor {
    background-color: var(--DarkGrey2-Color) !important;
}

.DarkGrey3_Color {
    color: var(--DarkGrey3-Color);
}

.DarkGrey3_BgColor {
    background-color: var(--DarkGrey3-Color) !important;
}

.DarkGrey4_Color {
    color: var(--DarkGrey4-Color);
}

.DarkGrey4_BgColor {
    background-color: var(--DarkGrey4-Color) !important;
}

.LightBlue_Color {
    color: var(--LightBlue-Color);
}

.LightBlue_BgColor {
    background-color: var(--LightBlue-Color) !important;
}

.LgithBlue2_Color {
    color: var(--LightBlue2-Color);
}

.LightBlue2_BgColor {
    background-color: var(--LightBlue2-Color) !important;
}

.LgithBlue3_Color {
    color: var(--LightBlue3-Color);
}

.LightBlue3_BgColor {
    background-color: var(--LightBlue3-Color) !important;
}

.LgithBlue4_Color {
    color: var(--LightBlue4-Color);
}

.LightBlue4_BgColor {
    background-color: var(--LightBlue4-Color) !important;
}

.DarkBlue_Color {
    color: var(--DarkBlue-Color);
}

.DarkBlue_BgColor {
    background-color: var(--DarkBlue-Color);
}

.DarkBlue2_Color {
    color: var(--DarkBlue2-Color) !important;
}

.DarkBlue2_BgColor {
    background-color: var(--DarkBlue2-Color);
}

.DarkBlue3_Color {
    color: var(--DarkBlue3-Color) !important;
}

.DarkBlue3_BgColor {
    background-color: var(--DarkBlue3-Color) !important;
}

.DarkBlue4_Color {
    color: var(--DarkBlue4-Color) !important;
}

.DarkBlue4_BgColor {
    background-color: var(--DarkBlue4-Color) !important;
}

.DarkBlue5_Color {
    color: var(--DarkBlue5-Color) !important;
}

.DarkBlue5_BgColor {
    background-color: var(--DarkBlue5-Color) !important;
}

.LightGreen_Color {
    color: var(--LightGreen-Color);
}

.LightGreen_BgColor {
    background-color: var(--LightGreen-Color) !important;
}

.LightGreen2_Color {
    color: var(--LightGreen2-Color);
}

.LightGreen2_BgColor {
    background-color: var(--LightGreen2-Color) !important;
}

.LightGreen3_Color {
    color: var(--LightGreen3-Color);
}

.LightGreen3_BgColor {
    background-color: var(--LightGreen3-Color) !important;
}

.LightOrange_Color {
    color: var(--LightOrange-Color);
}

.LightOrange_BgColor {
    background-color: var(--LightOrange-Color) !important;
}

.BlugGradBtn {
    background: linear-gradient(90deg, #24397A 3.64%, #2B4596 50.15%, #314EA9 96.82%);
}

.BlugGradBtn:hover {
    background: linear-gradient(90deg, #314EA9 36.82%, #2B4596 50.15%,  #24397A 93.64%);
    box-shadow: 10px 10px 10px #D6D6D6;
}

.font_8 {
    font-size: 0.5rem !important;
}

.font_9 {
    font-size: 0.5625rem !important;
}

.font_10 {
    font-size: 0.625rem !important;
}

.font_12 {
    font-size: 0.75rem;
}
  
.font_14 {
    font-size: 0.875rem;
}
  
.font_16 {
    font-size: 1rem;
}
  
.font_17 {
    font-size: 1.0625rem;
}
  
.font_18 {
    font-size: 1.125rem;
}
  
.font_19 {
    font-size: 1.1875rem;
}
  
.font_20 {
    font-size: 1.25rem !important; 
}

.font_21{
    font-size: 1.3125rem;
}

.font_22 {
    font-size: 1.375rem;
}

.font_23 {
    font-size: 1.4375rem;
}

.font_24 {
    font-size: 1.5rem;
}

.font_25 {
    font-size: 1.5625rem;
}

.font_26 {
    font-size: 1.625rem;
}

.font_27 {
    font-size: 1.6875rem;
}

.font_28 {
    font-size: 1.75rem;
}

.font_29 {
    font-size: 1.8125rem;
}

.font_30 {
    font-size: 1.875rem;
}

.font_31 {
    font-size: 1.9375rem;
}

.font_32 {
    font-size: 2rem;
}

.font_33 {
    font-size: 2.0625rem;
}

.font_34 {
    font-size: 2.125rem;
}

.font_35 {
    font-size: 2.1875rem;
}

.font_36 {
    font-size: 2.25rem;
}

.font_37 {
    font-size: 2.3125rem;
}

.font_38 {
    font-size: 2.375rem;
}

.font_39 {
    font-size: 2.4375rem;
}

.font_40 {
    font-size: 2.5rem;
}

.font_50 {
    font-size: 3.125rem;
}

.font_60 {
    font-size: 3.75rem;
}

.font_64 {
    font-size: 4rem;
}

.font_70 {
    font-size: 4.375rem;
}

.font_weight_300 {
    font-weight: 300;
}

.font_weight_400 {
    font-weight: 400;
}
  
.font_weight_500 {
    font-weight: 500 !important;
}
  
.font_weight_600 {
    font-weight: 600;
}
  
.font_weight_700 {
    font-weight: 700;
}
  
.font_weight_800 {
    font-weight: 800;
}
  
.font_weight_900 {
    font-weight: 900;
}

.LineHeight22 {
    line-height: 22px;
}

.LineHeight24 {
    line-height: 24.2px;
}

.LineHeight29 {
    line-height: 29.05px;
}

  /* /1. Common Styles Ending */


/* 2. Login Page Styles Starting */
.LoginBg {
    height: 100%;    
}

#SignUpLink {
  color: var(--DarkBlue2-Color);
}

#LoginEmailInputErr,
#LoginPasswordInputErr,
#LoginTestPasswordInputErr {
    visibility: hidden;
    /* visibility: visible; */
}


.PassShowBtn {
    border: 0px solid transparent;
    top: 25px;
    right: 0;
    color: black;
}

.ShowPassBtn {
    color: #212529;
    top: 28px;
    right: 5px;
    border:1px solid #212529;
}

.DarkBackGroupForTopHeader {
    position: absolute;
    bottom: 100%;
    width: 100%;
    /* background: linear-gradient(180deg, #182286 0%, #313FD0 100%); */
    background-color: #182286;
    height: 200px;
    /* border: 2px solid red; */
    z-index: -1;
}

#DashboardBackground {
    width: 100%;
    min-height: 90vh;
    background: linear-gradient(180deg, #182286 0%, #313FD0 100%) ,linear-gradient(0deg, #FFFFFF, #FFFFFF);

}

.Frame1,
.Frame2 {
    position: absolute;
    bottom: 70%;
    width: 400px !important; 
}

.Frame1 {
    left: 0;
    rotate: 180deg;
}

.Frame2 {
    right: 0;
}

.Object1,
.Object2,
.Object3,
.Object4,
.Object5,
.Object6 {
    position: absolute;
    width: 80px !important;
}

.Object1 {
    top: 70%; 
    left: 10%;
}

.Object2 {
    top: 40%;
    left: 30%;
}

.Object3 {
    top: 78%;
    left: 30%;
}

.Object4 {
    top: 40%;
    right: 30%;
}

.Object5 {
    top: 70%;
    right: 30%;
}

.Object6 {
    top: 45%;
    right: 20%;
}

.MyBtnSize {
    width: 170px ;
}

#TopHeaderNavBar1 {
    position: relative;
    z-index: 1;
}

/* .LoginBg {
    background-image: url(./../Images/LoginBg.webp);
    background-repeat: no-repeat;
    background-size:100%;
    height: 100%;
    background-color: var(--LightGrey-Color);
} */
/* 2. /Login Page Styles Ending */

/* 3. Enter Code Page Styles Starting*/

/* 3. /Enter Code Page Styles Ending*/

/*  4. Forgot Password Page Styles Starting */
/*  /4. Forgot Password Page Styles Ending */
/* 

    5. Sign Up Page Styles
	Enter Code Page Styles
    Registration/Create Account Page Styles
    Home Page Styles
    Demo Site Styles
    Calculate Score Page Styles
    Select Biometric Page Styles
    Resume Parsing Page Style
*/
  
  /* 3. Enter Code Page Styles */
/* 4. Registration/Create Account Page Styles */
.FormGroup {
    padding-bottom: 9px;
}
/* /4. Registration/Create Account Page Styles */

/* -------- 5. Home Page Styles Starting --------*/

#BodyBackGround {
    background-image: linear-gradient(to left, #182286 , #313FD0);
}
.nav-link {
    color: white !important;
    margin: 0 5px;
    border: 1px solid var(--DarkBlue3-Color);
}

.navbar-nav .nav-item .nav-link.active, .navbar-nav .show>.nav-link {
    color: var(--LightGreen-Color) !important;
    border: 1px solid var(--LightGreen-Color);
    border-radius: 4px;
}

.nav-link:hover {
    border: 1px solid var(--LightGreen-Color);
    color: var(--LightGreen-Color);
    border-radius: 4px;
}

.navbar-toggler-icon {
    background-color: var(--LightGreen-Color);
}

#ProfileDropdown.dropdown-menu[data-bs-popper] {
    left: initial !important;
    right: 0% !important;
}

.nav-tabs#DemoSiteNavTabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: white !important;
    background-color: var(--DarkBlue3-Color) !important;
    border-radius: 4px;
}

.nav-tabs#DemoSiteNavTabs .nav-link:hover, .nav-tabs .nav-link:focus {
    color: white !important;
    background-color: var(--LightGreen-Color) !important;
    border-radius: 4px;
}

.nav-tabs#DemoSiteNavTabs .nav-link  {
    color: var(--DarkBlue2-Color) !important;
    background-color: var(--LightGrey2-Color) !important;
    border-radius: 4px;
}


.nav-tabs#DemoSiteNavTabs {
    border: 0;
    display: flex;
    align-self: center;
    justify-content: center;
}

.NavTabIconSize {
    width: 25px !important;
}


.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}
  
.ParsingResumeButton {
    border: 2px solid gray;
    color: gray;
    background-color: var(--DarkBlue2-Color);
    padding: 8px 20px;
    border-radius: 8px;
    
}
  
.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: white !important;
    background-color: var(--DarkBlue3-Color) !important;
    border-radius: 4px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: var(--DarkBlue2-Color) !important;
    background-color: var(--LightGrey2-Color) !important;
    border-radius: 4px;
}

.css-heg063-MuiTabs-flexContainer {
    justify-content: center;
}

/* .FileUploadContainer1 {
    border: 2px solid red;
} */

.FileUploadContainer1.files {
    background: var(--LightVoilet-Color);
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 10px;
}

#uploadPdfFiles[type=file] {
    position: absolute;
    /* right: -9999px; */
    visibility: hidden;
    opacity: 0;
    z-index: 2;
}

#uploadPdfFiles {
    /* border: 2px solid red; */
}

#uploadPdfFiles[type=submit] {
    position: relative;
    padding: 1rem 3rem;
    background: var(--DarkVoilet-Color);
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    border: 0;
    color: #fff;
}

#uploadPdfFiles[type=submit]:hover {
    background: var(--DarkVoilet-Color);
    color: #fff;
    cursor: pointer;
    transition: 0.2s all;
}

.FileUploadLable1 {
    cursor: pointer;
}

#upload1[type=file] {
    position: absolute;
    /* right: -9999px; */
    visibility: hidden;
    width: 100px;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}

#upload1 {
    /* border: 2px solid red; */
    cursor: pointer;
}

#upload1[type=submit] {
    position: relative;
    padding: 1rem 3rem;
    background: var(--DarkVoilet-Color);
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    border: 0;
    color: #fff;
}

#upload1[type=submit]:hover {
    background: var(--DarkVoilet-Color);
    color: #fff;
    cursor: pointer;
    transition: 0.2s all;
}

#uploadFile1[type=file] {
    position: absolute;
    /* right: -9999px; */
    visibility: hidden;
    width: 100px;
    opacity: 0;
    z-index: 2;
}

#uploadFile1[type=submit] {
    position: relative;
    padding: 1rem 3rem;
    background: var(--DarkVoilet-Color);
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    border: 0;
    color: #fff;
}

#uploadFile1[type=submit]:hover {
    background: var(--DarkVoilet-Color);
    color: #fff;
    cursor: pointer;
    transition: 0.2s all;
}

#SingleResumeViewTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-weight: 600 !important;
}

.css-1aquho2-MuiTabs-indicator {
    background-color: transparent !important;
}

.accordion-item {
    margin-bottom: 10px;
    border-radius: 10px;
    /* border: 1px solid transparent !important; */
}

.accordion-item.accordion-button {
    border-radius: initial;    
}

.AccordionBody {
    padding: 8px 0;
    border-bottom: 1px solid #DDDDDD;
}

.AccordionBody:last-child {
    border: 0;
}

/* Check box show as a Button */
.cat{
    margin: 4px;
    background-color: #ECECEC;
    border-radius: 4px;
    border: 1px solid #fff;
    overflow: hidden;
    /* float: left; */
    text-align: center;
}
  
.cat label {
    /* float: left;  */
    line-height: 3.0em;
    cursor: pointer;
    /* width: 8.0em;  */
    /* height: 3.0em; */
}
  
.cat label span {
    text-align: center;
    padding: 3px 0;
    display: block;
}
  
.cat label input {
    position: absolute;
    display: none;
    color: #182286 !important;
}

.cat label input + span{
    color: #182286;
}

.cat input:checked + span {
    color: #ffffff;
    background-color: #182286 !important;
    text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
}

.css-19kzrtu {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.UploadSourceJobResumeTitle,
.UploadSourceJobResumeCheckBox { 
    cursor: pointer;
}

.UploadSourceJobResumeCheckBox:checked + .UploadSourceJobResumeTitle {
    color: #182286;
}

.UploadTargetResumeTitle,
.UploadTargetResumeCheckBox { 
    cursor: pointer;
}

.UploadTargetResumeCheckBox:checked + .UploadTargetResumeTitle {
    color: #7EAD18;
}

.ActionIcon {
    width: 18px;
}
/* /Check box show as a Button */
/* .ActionIcon {
    width: 25px;
} */
/* -------- /5. Home Page Styles Starting --------*/

/* 6. Demo Site Styles */
/* 7. Calculate Score Page Styles */
/* 8. Select Biometric Page Styles */
/* 9. Resume Parsing Page Style */

/* 
* {
    border: 1px solid red;
} */

#progressInput {
    margin: 20px auto;
    width: 30%;
  }
  
  .circle-background,
  .circle-progress {
    fill: none;
  }
  
  .circle-background {
    stroke: #ddd;
  }
  
  .circle-progress {
    stroke: red;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
.circle-text {
    font-size: 3em;
    font-weight: bold;
    fill: red;
}

#JobTitleRange,
#SkillsRange,
#CertificationsRange,
#OverAllRange {
    position: relative;
    z-index: 3;
    cursor: pointer;
}


#JobTitleRange {
    accent-color: #7695F8;
}

#SkillsRange {
    accent-color: #8CD88A;
}

#CertificationsRange {
    accent-color: #D48E39;
 
}
#OverAllRange {
    accent-color: #8C5EC7;
}

.OverAllRangeInput {
    /* transform: rotate(90deg); */
    /* border: 2px solid red; */
    width: 100%;
}

/* .OverAllRangeInput input {
    width: 100%;
} */



@media (min-width: 1700px) and (max-width: 2699px) {
    /* .ProgressBarIconImage {
        position: initial;
        transform: translate(-50%, -50%);
        border: 2px solid red;
        bottom: initial !important;
        left: initial;
    } */
}

@media (min-width: 1200px) and (max-width: 1699px) {
    /* .OverAllRangeInput {
        transform: rotate(0deg);
    } */
    /* .ProgressBarIconImage {
        position: initial;
        transform: translate(-50%, -50%);
        border: 2px solid green;
        bottom: initial !important;
        left: initial;
    } */
}

@media (min-width: 992px) and (max-width: 1199px) {
    /* .OverAllRangeInput {
        transform: rotate(0deg);
    } */
    /* .ProgressBarIconImage {
        position: initial;
        transform: translate(-50%, -50%);
        border: 2px solid green;
        bottom: initial !important;
        left: initial;
    } */
}

@media (min-width: 200px) and (max-width: 575px) {
    /* .OverAllRangeInput {
        transform: rotate(0deg);
    } */

    /* .ParentContainerCircleBar:first-child {
        border: 2px solid green;
    } */

    /* .ParentCircleBar {
        border: 2px solid red;
    } */

    /* .ProgressBarIconImage {
        position: absolute;
        transform: translate(-50%, -50%);
        border: 2px solid green;
        bottom: 130% !important;
        left: 13%;
    } */

    .ParentContainerCircleBar>div>div>div {
        /* border: 2px solid green; */
        width: 48px !important;
        height: 48px !important;
        margin-top: -24px !important;
        position: absolute;
        transform: translate(50%, -85%);
    
    }
}

/* Side Navigation */
#SidebarWrapper {
    background-color: var(--DarkBlue5-Color);
    height: 100vh;
    width: 300px;
}

.SideNavItem {
    background-color: var(--DarkBlue5-Color) !important;
    padding: 0 !important;
    border: 0 !important;
    vertical-align:middle;
}

.SideNavItem .SideNavLink {
    color: #fff;
}

.SideNavLink {
    display: block;
    padding: 10px 20px;
    border: 0 !important;
    text-decoration: none;
    font-weight: 300;
    /* font-size: 20px; */
}

.SideNavItem.active  .SideNavLink {
    color: #7EAD18 !important;
    background: #1B2593;
    font-weight: 500;
    /* font-size: 20px; */
}

.active {
    font-weight: bold;
    /* color: "red"; */
    color: #7EAD18 !important;
    background: #1B2593;
    font-weight: 500;
 }

 .MainBodyFullWidth {
    width: 100%;
 }


.CircularProgressbar {
    width: 100px !important;
}

.TestIcons {
    position: absolute;
    width: 90px;
    z-index: -1;
    /* top: 150px; */
}

#TestIcon1 {
    top: 17%;
    left: 15%;
}

#TestIcon2 {
    top: 15%;
    left: 40%;
}

#TestIcon3 {
    top: 11%;
    right: 5%;
}

#TestIcon4 {
    top: 53%;
    left: 15%;
}

#TestIcon5 {
    top: 53%;
    left: 30%;
}

#TestIcon6 {
    top: 40%;
    left: 45%;
}

#TestIcon7 {
    top: 73%;
    left: 60%;
}

#TestIcon8 {
    top: 71%;
    right: 5%;
}

.TestContentContainer {
    margin-top: 100px;
    width: 80%;
    padding-left: 5%;
}

.TestImg,
.AnswerTestImg1,
.TestImage1 {
    border: 12px solid #B7BDF3;
    background-color: #B7BDF3;
    border-radius: 8px;
}

.TestObject>.form-check-input[type=checkbox] {
    background-color: var(--LightGrey9-Color);
    border-color: var(--LightGrey9-Color);
}

.TestObject>.form-check-input:checked {
    background-color: var(--DarkBlue4-Color);
    border-color: var(--DarkBlue4-Color);
}


.AnswerOption>div>.form-check-input[type=radio] {
    background-color: var(--LightGrey9-Color);
    border-color: var(--LightGrey9-Color);
    /* border-radius: initial; */
}

.AnswerOption>div>.form-check-input:checked {
    background-color: var(--DarkBlue4-Color);
    border-color: var(--DarkBlue4-Color);
}

.Test_Icon {
    width: 35px;
    height: 35px;
}

.QuestionTypeContainer2 {
    height: 87vh;
}

.QuestionType2 {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.QuestionContainerHeight {
    height: 400px;
    overflow-y: auto;
}

.CodingContainerHeight {
    height: 310px;
}

.ShowCodingAnswerForQuestion {
    outline: 0 !important;
}

/* Video Record Styles Starting */
.button-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .audio-controls,
  .video-controls {
      margin-bottom: 20px;
  }
  .audio-player,
  .video-player {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .audio-player,
  .video-player,
  .recorded-player {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .live-player {
      height: 200px;
      width: 400px;
      border: 1px solid #646cff;
      margin-bottom: 30px;
  }
  .recorded-player video {
      height: 400px;
      width: 800px;
  }
/* /Video Record Styles Ending */


.code-mirror-wrapper>div:first-child{
    height: 350px;
}
.paddingcustom{
    padding: 5px;
    margin-left: 5px ;
}

@media only screen and (max-width: 768px) {
    img{
        max-width: 100%;
    }
    .font_18{
        font-size: 14px;
    }
    .font_64{
        font-size: 24px;
    }
    .font_22{
        font-size: 16px;
    }
    .demositemobile{
        margin-bottom: 10px;
    }
    .mobileMarging{
        margin-bottom: 5px;
    }
    .mobileMarging10{
        margin-bottom: 15px;
    }
    .mobilehide{
        display: none;
    }
}

.container2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.spinner-container{
    text-align: center;
}
.volumeid{
    --volume: 0%;
    position: relative;
    width: 200px;
    height: 20px;
    margin: 50px;
    background-color: #DDD;
}

.volumeid::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--volume);
    background-color: green;
    transition: width 100ms linear;
 }
 .volumebar{
    width: 78%;
    
 }
 .volumebar, .button{
    display: inline-block;
    float: left;
 }
 .button button{
    font-size: 12px;
 }
 .miccontainer{
    height: 25px;
    display: flex;
    gap: 10px;
 }
 .bar {
    width: 5px;
  
    height: 25px;
    border-radius: 100px;
    background: #ddd;
  }
  
@media screen and (max-width: 1366px) and (max-height: 800px){
    .font_60{
        font-size: 2.6rem;
    }
    .TestImgContainer{
        width: 80%;
    }
    .inmobilechange{
        margin-top: 0 !important;
        padding-top: 0px !important;
    }
    .font_30 {
        font-size: 1.3rem !important;
    }
    .font_24 {
        font-size: 1.0rem !important;
    }
    .AnswerVideoContainer video{
        height: 300px;
    }
}
.CandidateAllTestList,
.VideoFileList {
    list-style-type: none;
    padding-left: 0;
    /* height: 80vh;
    overflow-y: auto;
    overflow-x: hidden; */
}

.TestQuestionVideoTitle {
    /* height: 60px; */
    margin: 0 0 4px 0;
    line-height: 2rem;
    font-weight: 500;
    overflow: hidden;
    display: block;
    max-height: 4rem;
    -webkit-line-clamp: 2;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
}

.CandidateTestItem.activeOne {
    background-color: var(--DarkBlue-Color);
    color: #fff;
    font-weight: 500;
}

.CandidateTestItem {
    padding: 10px 0;
    margin: 3px 0;
    background-color: ghostwhite;
    color: var(--DarkBlue3-Color);
    cursor: pointer;
}

.CandidateTestItem:hover {
    box-shadow: 0px 0px 5px #000;
}

.ScoreInfo,
.CutoffScoreInfo {
    color: #606060;
    font-weight: 500;
}

.AnswerIs {
    max-height: 150px;
    overflow: auto;
    white-space: pre;
}

.CandidateInformation label {
    /* color : var(--LightGreen-Color); */
    color: var(--DarkBlue4-Color);
}

.CandidateInformationHeading {
    color: #fff;
    background-color: var(--DarkBlue-Color);
}

.form-field-label {
    font-size: 0.95rem;
}

.VideoFileItem .bg-dark.activeVideo, 
.VideoFileItem .bg-light.activeVideo {
    /* background-color:var(--LightGreen3-Color) !important; */
    background-color:var(--LightOrange-Color) !important;
}

.jdskills-list{
    list-style: none;
    margin: 0;
    padding: 0;
}

.jdskills-list li{
    display: inline-block;
    padding: 5px 10px;
    border:1px solid #333;
    margin: 5px;
    border-radius: 4px;
    position: relative;
}
.jdskillclose{
    position: absolute;
    top: -15px;
    right: -10px;
    display: none;
}

.jdskills-list li:hover .jdskillclose{
    display: block;
}

